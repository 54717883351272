<template>
  <component :is="commonComponent" id="privacy" title="Privacy policy">
    <section id="privacy-content" class="pc_padding">
      <div class="common-content-row">
        TimeCheckNow values your privacy. This Privacy Policy (&quot;Policy&quot;) describes how we
        collect, use, and protect
        your personal information. By accessing or using the Website, you agree to the practices
        described in this Policy.
      </div>

      <div class="common-content-head">
        <span>1</span> Introduction
      </div>
      <div class="common-content-row">
        At TimeCheckNow.com, we are committed to protecting your privacy. This Privacy Policy
        explains how we collect, use, disclose, and safeguard your information when you visit our
        website.
      </div>

      <div class="common-content-head">
        <span>2</span> Information We Collect
      </div>
      <div class="common-content-row">
        We may collect information about you in a variety of ways. The information we may collect on
        the site includes:
      </div>
      <div class="common-content-row">
        <ul>
          <li>
            <strong>Personal Data</strong>: Personally identifiable information, such as your name,
            shipping address, email address, and telephone number, and demographic information, such
            as your age, gender, hometown, and interests, that you voluntarily give to us when you
            register with the site or when you choose to participate in various activities related
            to the site.
          </li>
          <li>
            <strong>Derivative Data</strong>: Information our servers automatically collect when you
            access the site, such as your IP address, your browser type, your operating system, your
            access times, and the pages you have viewed directly before and after accessing the
            site.
          </li>
          <li>
            <strong>Financial Data</strong>: Financial information, such as data related to your
            payment method (e.g., valid credit card number, card brand, expiration date) that we may
            collect when you purchase, order, return, exchange, or request information about our
            services from the site.
          </li>
          <li>
            <strong>Mobile Device Data</strong>: Device information, such as your mobile device ID,
            model, and manufacturer, and information about the location of your device, if you
            access the site from a mobile device.
          </li>
        </ul>
      </div>

      <div class="common-content-head">
        <span>3</span> Use of Your Information
      </div>
      <div class="common-content-row">
        Having accurate information about you permits us to provide you with a smooth, efficient,
        and customized experience. Specifically, we may use information collected about you via the
        site to:
      </div>
      <div class="common-content-row">
        <ul>
          <li>Create and manage your account.</li>
          <li>Process your transactions and send you related information, including purchase
            confirmations and invoices.</li>
          <li>Manage your orders and deliver the products and services you request.</li>
          <li>Improve our website to better serve you.</li>
          <li>Send you marketing and promotional communications.</li>
          <li>Respond to your comments, questions, and provide customer service.</li>
          <li>Monitor and analyze usage and trends to improve your experience with the site.</li>
          <li>Prevent fraudulent transactions, monitor against theft, and protect against criminal
            activity.</li>
        </ul>
      </div>

      <div class="common-content-head">
        <span>4</span> Disclosure of Your Information
      </div>
      <div class="common-content-row">
        We may share information we have collected about you in certain situations. Your information
        may be disclosed as follows:
      </div>
      <div class="common-content-row">
        <ul>
          <li>
            <strong>By Law or to Protect Rights</strong>: If we believe the release of information
            about you is necessary to respond to legal process, to investigate or remedy potential
            violations of our policies, or to protect the rights, property, and safety of others, we
            may share your information as permitted or required by any applicable law, rule, or
            regulation.
          </li>
          <li>
            <strong>Third-Party Service Providers</strong>: We may share your information with third
            parties that perform services for us or on our behalf, including payment processing,
            data analysis, email delivery, hosting services, customer service, and marketing
            assistance.
          </li>
          <li>
            <strong>Business Transfers</strong>: We may share or transfer your information in
            connection with, or during negotiations of, any merger, sale of company assets,
            financing, or acquisition of all or a portion of our business to another company.
          </li>
          <li>
            <strong>With Your Consent</strong>: We may disclose your personal information for any
            other purpose with your consent.
          </li>
        </ul>
      </div>

      <div class="common-content-head">
        <span>5</span> Security of Your Information
      </div>
      <div class="common-content-row">
        We use administrative, technical, and physical security measures to help protect your
        personal information. While we have taken reasonable steps to secure the personal
        information you provide to us, please be aware that despite our efforts, no security
        measures are perfect or impenetrable, and no method of data transmission can be guaranteed
        against any interception or other type of misuse.
      </div>

      <div class="common-content-head">
        <span>6</span> Policy for Children
      </div>
      <div class="common-content-row">
        We do not knowingly solicit information from or market to children under the age of 13. If
        we learn that we have collected personal information from a child under age 13 without
        verification of parental consent, we will delete that information as quickly as possible.
      </div>

      <div class="common-content-head">
        <span>7</span> Changes to This Privacy Policy
      </div>
      <div class="common-content-row">
        We may update this Privacy Policy from time to time in order to reflect, for example,
        changes to our practices or for other operational, legal, or regulatory reasons. We will
        notify you of any changes by posting the new Privacy Policy on our site. You are advised to
        review this Privacy Policy periodically for any changes.
      </div>

      <div class="common-content-head">
        <span>8</span> Contact Us
      </div>
      <div class="common-content-row">
        If you have questions or comments about this Privacy Policy, please contact us through our
        contact page.
      </div>
    </section>
  </component>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
// 按需动态引入 PC 和 Mobile 版本的 CommonPage
const PcCommon = () => import('@/pages/components/pc_common.vue');
const MobileCommon = () => import('@/pages/components/m_common.vue');

import '@/css/common.scss';

export default {
  mixins: [common],
  data () {
    return {
      commonComponent: null,
    };
  },
  metaInfo () {
    return {
      title: 'Privacy & Policy',
    };
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'deviceType',
      'host',
    ])
  },
  created () {
    // 根据 deviceType 动态加载对应的组件
    this.commonComponent = this.deviceType === 'desktop' || this.deviceType === 'pc' ? PcCommon : MobileCommon;
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
};
</script>
